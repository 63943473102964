import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { HeaderMenu } from "./HeaderMenu";
import styles from "./style.module.scss";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const [imageName, setImageName] = useState<string>("");
  const [content, setContent] = useState<
    {
      title: string;
      redirect: string;
    }[]
  >([]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.mobileItem}>
          <img
            src="/assets/eyeIcon.svg"
            alt="eye"
            onClick={(e) => {
              setMenu(e.currentTarget);
              setContent([
                { title: "Accueil", redirect: "/home" },
                { title: "Travaux", redirect: "/works" },
              ]);
              setImageName("eyeIcon.svg");
            }}
          />
        </div>
        <div className={styles.menuBlank}></div>
        <div
          className={
            location.pathname.includes("/home")
              ? `${styles.menuItem} ${styles.menuItemSelected}`
              : styles.menuItem
          }
          onClick={() => navigate("/home")}
        >
          Accueil
        </div>
        <div
          className={
            location.pathname.includes("/works")
              ? `${styles.menuItem} ${styles.menuItemSelected}`
              : styles.menuItem
          }
          onClick={() => navigate("/works")}
        >
          Travaux
        </div>
        <div className={styles.titleItem} onClick={() => navigate("/home")}>
          Thibault Chauvet
        </div>
        <div
          className={
            location.pathname.includes("/about")
              ? `${styles.menuItem} ${styles.menuItemSelected}`
              : styles.menuItem
          }
          onClick={() => navigate("/about")}
        >
          <div>A-propos</div>
        </div>
        <div
          className={
            location.pathname.includes("/contact")
              ? `${styles.menuItem} ${styles.menuItemSelected}`
              : styles.menuItem
          }
          onClick={() => navigate("/contact")}
        >
          Contact
        </div>
        <div className={styles.menuBlank}></div>
        <div className={styles.mobileItem}>
          <img
            src="/assets/sunIcon.svg"
            alt="sun"
            onClick={(e) => {
              setMenu(e.currentTarget);
              setContent([
                { title: "A-propos", redirect: "/about" },
                { title: "Contact", redirect: "/contact" },
              ]);
              setImageName("sunIcon.svg");
            }}
          />
        </div>
      </div>
      {!!menu && (
        <HeaderMenu
          menu={menu}
          setMenu={setMenu}
          navigate={navigate}
          content={content}
          setContent={setContent}
          imageName={imageName}
        />
      )}
    </>
  );
};
