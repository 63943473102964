import { ImageOverlay } from "../ImageOverlay/ImageOverlay";
import React from "react";
import styles from "./style.module.scss";

interface Props {
  setZoomed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PersonalityTestIntro = (props: Props) => {
  const { setZoomed } = props;

  return (
    <div className={styles.content}>
      <div className={styles.pageContent}>
        <div className={styles.highHalf}>
          <div className={styles.leftDiv}>
            <img
              className={styles.image}
              alt="exploInvisible"
              src="/assets/exploInvisible.svg"
            />
          </div>
          <div className={styles.rightDivDiv}>
            <ImageOverlay src="/assets/feuille.svg">
              <div className={styles.textContent}>
                <div>
                  Sous le voile étoilé de l'univers, nous plongeons dans le
                  mystère, là où le passé, le présent et le futur dansent en
                  harmonie.
                </div>
                <div>
                  À travers chaque arcane, une histoire se dessine, une vérité
                  se dévoile, une lumière perce.
                </div>
                <div className={styles.blueText}>
                  Laissez les cartes tisser leur toile et vous choisir.
                </div>
                <div className={styles.brownText}>
                  Soyez sincères dans vos réponses. Évitez de rester neutre
                  autant que possible.
                </div>
              </div>
            </ImageOverlay>
          </div>
        </div>
        <div className={styles.lowHalf}>
          <div className={styles.button} onClick={() => setZoomed(true)}>
            <img src="/assets/oracleButton.svg" alt="oracleButton" />
          </div>
        </div>
      </div>
    </div>
  );
};
