import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  imageName: string;
  year: string;
  navigateTo: string;
}

export const WorksMenuItem = (props: Props) => {
  const { imageName, year, navigateTo } = props;

  const navigate = useNavigate();

  return (
    <div className={styles.container} onClick={() => navigate(navigateTo)}>
      <img
        className={styles.image}
        alt={imageName}
        src={`/assets/${imageName}.png`}
      />
      <div className={styles.year}>{year}</div>
    </div>
  );
};
