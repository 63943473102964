import React from "react";
import styles from "./style.module.scss";

interface Props {
  children: React.ReactNode;
  backgroundImage: string;
  responsiveDisplayNone?: boolean;
}

export const Page = (props: Props) => {
  const { children, backgroundImage, responsiveDisplayNone } = props;

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        display: responsiveDisplayNone ? "none" : "",
      }}
    >
      {children}
    </div>
  );
};
