/* eslint-disable react/jsx-no-target-blank */
import { Page } from "../Page/Page";
import React from "react";
import styles from "./style.module.scss";

export const Legalnotice = () => {
  return (
    <Page backgroundImage="/assets/rue_neon.jpg">
      <div className={styles.box}>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>Un site développé par :</div>
          Clément Baby
          <br />
          <a href="https://www.clement-baby.fr" target="_blank">
            www.clement-baby.fr
          </a>
        </div>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>Webdesign :</div>
          Thibault Chauvet
        </div>
        <div className={styles.boxContent}>
          <div className={styles.boxHeader}>Images :</div>
          Unsplash
        </div>
      </div>
    </Page>
  );
};
