import { Page } from "../Page/Page";
import React from "react";
import styles from "./style.module.scss";

interface Props {
  imageTitle: string;
  description: string[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const WorkIntro = (props: Props) => {
  const { imageTitle, description, setPage } = props;

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.box}>
            <div className={styles.boxContent}>
              <img
                className={styles.image}
                alt="test"
                src={`/assets/${imageTitle}`}
              />
              {description.map((desc, index) => {
                return (
                  <div key={index} className={styles.description}>
                    {desc}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div onClick={() => setPage(1)}>
            <div className={styles.wayMobile}>
              <img alt="arrow" src={`/assets/arrowRight.svg`} />
            </div>
            <div className={styles.wayComputer}>
              <div className={styles.wayText}>Sens de la visite</div>
              <img
                className={styles.wayImage}
                alt="arrow"
                src={`/assets/arrowRight.svg`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
