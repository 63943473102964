import { useLocation, useNavigate } from "react-router-dom";

import React from "react";
import styles from "./style.module.scss";

export const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.footer}>
      <div className={styles.footerItem}>© Thibault Chauvet Copyright 2024</div>
      <div className={styles.footerItem}>
        Artist plasticien - Visual artist - ♡
      </div>
      <div className={styles.footerItemButton}>
        <div
          className={
            location.pathname === "/legalnotice"
              ? `${styles.footerButton} ${styles.footerButtonSelected}`
              : styles.footerButton
          }
          onClick={() => navigate("/legalnotice")}
        >
          Mentions légales
        </div>
      </div>
    </div>
  );
};
