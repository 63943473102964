import React, { useState } from "react";

import { QuestionCard } from "../QuestionCard/QuestionCard";
import chunk from "lodash.chunk";
import personalityTest from "../../personalityTest.json";
import styles from "./style.module.scss";

export const PersonalityTestContent = () => {
  const questions = personalityTest.data;

  const [chunkIndex, setChunkIndex] = useState(0);

  const questionsLines = chunk(chunk(questions, 4), 3);

  const [responses, setResponses] = useState<(string | null)[]>(
    questions.map((_) => null)
  );

  console.log(responses);

  return (
    <>
      {chunkIndex < 4 ? (
        <div className={styles.box}>
          {questionsLines[chunkIndex].map((line, lineIdx) => (
            <div key={`line-${lineIdx}`} className={styles.line}>
              {line.map((question, idx) => (
                <QuestionCard
                  key={`card-${idx}`}
                  chunkIndex={chunkIndex}
                  questionIndex={idx + lineIdx * 4}
                  question={question}
                  responses={responses}
                  setResponses={setResponses}
                  setChunkIndex={setChunkIndex}
                />
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.wait}>
          <div className={styles.gif}>
            <img src="/assets/boule.gif" alt="boule" className={styles.boule} />
            <img
              src="/assets/marbre.png"
              alt="marbre"
              className={styles.marbre}
            />
          </div>
          <img src="/assets/waiting_test.svg" alt="waiting" />
        </div>
      )}
    </>
  );
};
