import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Page } from "../Page/Page";
import { WorkCard } from "../WorkCard/WorkCard";
import styles from "./style.module.scss";

interface Artwork {
  image: string;
  title: string;
  dimensions: string;
  medium: string;
  date: string;
  type: string;
}

interface Props {
  artwork: Artwork;
  hasNextPage: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  images: JSX.Element[]
  mobileImages: JSX.Element[]
  move: Mvt
  setMove: Dispatch<SetStateAction<Mvt>>
}


export enum Mvt {
  Static = "Static",
  GoRight = "GoRight",
  GoLeft = "GoLeft",
  ArriveFromRight = "ArriveFromRight",
  ArriveFromLeft = "ArriveFromLeft",
}

export const WorkContent = (props: Props) => {
  const { artwork, page, hasNextPage, setPage, images, mobileImages, move, setMove } = props;

  const [isMoving, setIsMoving] = useState(true);

  useEffect(() => {
    setTimeout(
      () => {
        setIsMoving(false);
      },
      page === 1 ? 350 : 700
    );
  }, [move, setMove, page]);

  const getMvtStyle = () => {
    switch (move) {
      case Mvt.GoRight:
        return styles.contentSlideLeft;
      case Mvt.GoLeft:
        return styles.contentSlideRight;
      case Mvt.ArriveFromRight:
        return styles.contentSlideFromRight;
      case Mvt.ArriveFromLeft:
        return styles.contentSlideFromLeft;
      default:
        return styles.content;
    }
  };

  return (
    <Page backgroundImage="/assets/mur_oeuvre.jpg">
      <div className={styles.containerMobile}>
        {mobileImages[page-1]}
        <div>
          <div className={styles.arrow}>
            {!isMoving && (
              <img
                onClick={() => {
                  setIsMoving(true);
                  setMove(Mvt.GoLeft);
                  setTimeout(() => {
                    setPage(page - 1);
                    setMove(Mvt.ArriveFromLeft);
                  }, 350);
                }}
                alt="arrow"
                src="/assets/arrowLeft.svg"
              />
            )}
          </div>
          <div className={getMvtStyle()}>
            <WorkCard artwork={artwork} />
          </div>
          <div className={styles.arrow}>
            {hasNextPage && !isMoving && (
              <img
                onClick={() => {
                  setIsMoving(true);
                  setMove(Mvt.GoRight);
                  setTimeout(() => {
                    setPage(page + 1);
                    setMove(Mvt.ArriveFromRight);
                  }, 350);
                }}
                alt="arrow"
                src="/assets/arrowRight.svg"
              />
            )}
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.arrow}>
          {!isMoving && (
            <img
              onClick={() => {
                setIsMoving(true);
                setMove(Mvt.GoLeft);
                setTimeout(() => {
                  setPage(page - 1);
                  setMove(Mvt.ArriveFromLeft);
                }, 350);
              }}
              className={styles.wayLeft}
              alt="arrow"
              src="/assets/arrowLeft.svg"
            />
          )}
        </div>
        <div className={getMvtStyle()}>
          <div className={styles.artworkSide}></div>
          <div className={styles.artwork}>
            {images[page-1]}
          </div>
          <div className={styles.artworkSide}>
            <WorkCard artwork={artwork} />
          </div>
        </div>
        <div className={styles.arrow}>
          {hasNextPage && !isMoving && (
            <img
              onClick={() => {
                setIsMoving(true);
                setMove(Mvt.GoRight);
                setTimeout(() => {
                  setPage(page + 1);
                  setMove(Mvt.ArriveFromRight);
                }, 350);
              }}
              className={styles.wayRight}
              alt="arrow"
              src="/assets/arrowRight.svg"
            />
          )}
        </div>
      </div>
    </Page>
  );
};
