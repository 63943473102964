import { QuestionIcons } from "../QuestionIcons/QuestionIcons";
import React from "react";
import styles from "./style.module.scss";

interface Props {
  question: { question: string; agree: string; disagree: string };
  chunkIndex: number;
  questionIndex: number;
  responses: (string | null)[];
  setResponses: React.Dispatch<React.SetStateAction<(string | null)[]>>;
  setChunkIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const QuestionCard = (props: Props) => {
  const {
    question,
    chunkIndex,
    setChunkIndex,
    questionIndex,
    responses,
    setResponses,
  } = props;

  return (
    <div className={styles.questionBox}>
      <div className={styles.questionText}>{question.question}</div>
      <QuestionIcons
        question={question}
        chunkIndex={chunkIndex}
        questionIndex={questionIndex}
        responses={responses}
        setResponses={setResponses}
        setChunkIndex={setChunkIndex}
      />
      <div className={styles.detailInfos}>
        <div className={styles.redText}>Pas d'accord</div>
        <div className={styles.blueText}>D'accord</div>
      </div>
    </div>
  );
};
