import React, { useState } from "react";

import { Mvt, WorkContent } from "../WorkContent/WorkContent";
import { WorkIntro } from "../WorkIntro/WorkIntro";
import styles from './style.module.scss'

interface Props {
  data: {
    imageTitle: string;
    description: string[];
    artworks: {
      image: string;
      title: string;
      dimensions: string;
      medium: string;
      date: string;
      type: string;
    }[];
  };
}

export const Work = (props: Props) => {
  const { data } = props;
  const {artworks, imageTitle, description} = data

  const [page, setPage] = useState(0);
  const [move, setMove] = useState<Mvt>(Mvt.ArriveFromRight);

  const images = artworks.map(a => 
    <img
      className={
        a.type === "sculpture" ? styles.sculpture : styles.image
      }
      alt="artwork"
      src={`/assets/${a.image}`}
    />
  )

  const getArtworkStyle = (type: string) => {
    switch (move) {
      case Mvt.GoRight:
        return type === "sculpture"
          ? styles.sculptureSlideLeft
          : styles.imageSlideLeft;
      case Mvt.GoLeft:
        return type === "sculpture"
          ? styles.sculptureSlideRight
          : styles.imageSlideRight;
      case Mvt.ArriveFromRight:
        return type === "sculpture"
          ? styles.sculptureSlideFromRight
          : styles.imageSlideFromRight;
      case Mvt.ArriveFromLeft:
        return type === "sculpture"
          ? styles.sculptureSlideFromLeft
          : styles.imageSlideFromLeft;
      default:
        return styles.image;
    }
  }

  const mobileImages = artworks.map(a => 
    <img
      className={getArtworkStyle(a.type)}
      alt="artwork"
      src={`/assets/${a.image}`}
    />
  )

  return page === 0 ? (
    <WorkIntro
      imageTitle={imageTitle}
      description={description}
      setPage={setPage}
    />
  ) : (
    <WorkContent
      artwork={artworks[page - 1]}
      hasNextPage={page < artworks.length}
      page={page}
      setPage={setPage}
      images={images}
      mobileImages={mobileImages}
      move={move}
      setMove={setMove}
    />
  );
};
