import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { About } from "./components/About/About";
import { Contact } from "./components/Contact/Contact";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Home } from "./components/Home/Home";
import { Legalnotice } from "./components/Legalnotice/Legalnotice";
import { PersonalityTest } from "./components/PersonalityTest/PersonalityTest";
import React from "react";
import { StyledEngineProvider } from "@mui/material";
import { Work } from "./components/Work/Work";
import { WorksMenu } from "./components/WorksMenu/WorksMenu";
import data from "./content.json";
import styles from "./style.module.scss";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Router>
        <div className={styles.content}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/works" element={<WorksMenu />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/legalnotice" element={<Legalnotice />} />
            <Route path="/works/kaiju" element={<Work data={data.kaiju} />} />
            <Route
              path="/works/miroirdesens"
              element={<Work data={data.miroirDeSens} />}
            />
            <Route
              path="/works/paralogophobie"
              element={<Work data={data.paralogophobie} />}
            />
            <Route
              path="/works/paralogophobie2"
              element={<Work data={data.para2} />}
            />
            <Route path="/works/vrmr" element={<Work data={data.vrmr} />} />
            <Route path="/works/cheffe" element={<Work data={data.cheffe} />} />
            <Route
              path="/about/personalitytest"
              element={<PersonalityTest />}
            />
          </Routes>
          <Footer />
        </div>
      </Router>
    </StyledEngineProvider>
  );
};

export default App;
