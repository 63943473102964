import { Menu, MenuItem, Popover } from "@mui/material";

import { Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import React from "react";
import styles from "./style.module.scss";

interface Props {
  menu: HTMLElement | null;
  setMenu: Dispatch<React.SetStateAction<HTMLElement | null>>;
  navigate: NavigateFunction;
  content: {
    title: string;
    redirect: string;
  }[];
  setContent: Dispatch<
    React.SetStateAction<
      {
        title: string;
        redirect: string;
      }[]
    >
  >;
  imageName: string;
}

export const HeaderMenu = (props: Props) => {
  const { menu, setMenu, navigate, content, setContent, imageName } = props;

  const onClose = () => {
    setMenu(null);
    setContent([]);
  };

  return (
    <Menu
      className={styles.headerMenu}
      open={Boolean(menu)}
      onClose={onClose}
      anchorEl={menu}
      transitionDuration={0}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {imageName === "eyeIcon.svg" ? (
        <img
          style={{ paddingLeft: "20%" }}
          src={`/assets/${imageName}`}
          alt={imageName}
        />
      ) : (
        <img
          style={{ paddingLeft: "60%" }}
          src={`/assets/${imageName}`}
          alt={imageName}
        />
      )}
      {content.map((item, index) => (
        <div
          style={{
            textAlign: imageName === "eyeIcon.svg" ? "left" : "right",
            fontSize: "12px",
            padding: "6px 12px",
            whiteSpace: "nowrap",
            margin: "auto",
          }}
          key={index}
          onClick={() => {
            navigate(item.redirect);
            onClose();
          }}
        >
          {item.title}
        </div>
      ))}
    </Menu>
  );
};
