import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";

import { Page } from "../Page/Page";
import styles from "./style.module.scss";

const emailjs = require("@emailjs/browser");

export const Contact = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");

  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(false);
  const [hasFieldChanged, setHasFieldChanged] = useState({
    name: false,
    mail: false,
    object: false,
    message: false,
  });

  const formatMail = () => {
    return `Nom : ${name}\n\nMail : ${mail}\n\nObjet : ${object}\n\nMessage : ${message}`;
  };

  const onClick = () => {
    setIsSending(true);
    if (isSent) return;
    emailjs
      .send(
        "service_u61v8yy",
        "template_iboihjo",
        { mail, message: formatMail() },
        "kT7Llnw3ikOdd-6l3"
      )
      .then((response: { status: any; text: any }) => {
        setIsSent(true);
      })
      .catch((err: any) => {
        setError(err);
      });
  };

  return (
    <Page backgroundImage="/assets/porte3.jpg">
      <div className={styles.box}>
        <div>
          <div className={styles.formLine}>
            <Typography
              className={styles.typography}
              fontFamily={"Mustica Pro"}
            >
              Nom :
            </Typography>
            <TextField
              className={
                name === "" && hasFieldChanged.name
                  ? styles.textFieldEmpty
                  : styles.textField
              }
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setHasFieldChanged({ ...hasFieldChanged, name: true });
              }}
              size="small"
            />
          </div>
          <div className={styles.formLine}>
            <Typography
              className={styles.typography}
              fontFamily={"Mustica Pro"}
            >
              Mail :
            </Typography>
            <TextField
              className={
                mail === "" && hasFieldChanged.mail
                  ? styles.textFieldEmpty
                  : styles.textField
              }
              fullWidth
              value={mail}
              onChange={(e) => {
                setMail(e.target.value);
                setHasFieldChanged({ ...hasFieldChanged, mail: true });
              }}
              size="small"
            />
          </div>
          <div className={styles.formLine}>
            <Typography
              className={styles.typography}
              fontFamily={"Mustica Pro"}
            >
              Objet :
            </Typography>
            <TextField
              className={
                object === "" && hasFieldChanged.object
                  ? styles.textFieldEmpty
                  : styles.textField
              }
              fullWidth
              value={object}
              onChange={(e) => {
                setObject(e.target.value);
                setHasFieldChanged({ ...hasFieldChanged, object: true });
              }}
              size="small"
            />
          </div>
          <div className={styles.formLine}>
            <Typography
              className={styles.typography}
              fontFamily={"Mustica Pro"}
            >
              Message :
            </Typography>
            <TextField
              sx={{
                ".MuiInputBase-root": { padding: "0px" },
                ".MuiInputBase-input": {
                  fontSize: "0.8rem",
                  fontFamily: "Mustica Pro",
                },
              }}
              className={
                message === "" && hasFieldChanged.message
                  ? styles.textFieldEmpty
                  : styles.textField
              }
              fullWidth
              value={message}
              maxRows={5}
              multiline
              onChange={(e) => {
                setMessage(e.target.value);
                setHasFieldChanged({ ...hasFieldChanged, message: true });
              }}
              size="small"
            />
          </div>
        </div>
        <div
          className={styles.success}
          style={{ display: isSent ? "" : "none" }}
        >
          Email envoyé avec succès !
        </div>
        <div className={styles.error} style={{ display: error ? "" : "none" }}>
          Erreur lors de l'envoi du mail
        </div>
        <div
          className={
            name === "" ||
            mail === "" ||
            object === "" ||
            message === "" ||
            isSent ||
            isSending
              ? styles.disabled
              : styles.formButton
          }
          onClick={onClick}
        >
          Envoyer
        </div>
      </div>
    </Page>
  );
};
