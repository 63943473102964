import { Page } from "../Page/Page";
import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Page backgroundImage="/assets/entree.jpg">
        <div className={styles.container}>
          <div className={styles.door} onClick={() => navigate("/works")}></div>
          <div className={styles.box} onClick={() => navigate("/works")}>
            <div>
              Accepter de passer le seuil, c'est lâcher prise, s'élancer vers
              l'inconnu
            </div>
          </div>
          <div className={styles.box} onClick={() => navigate("/works")}>
            <div>Plonger en soi pour accepter de se connaître vraiment</div>
          </div>
        </div>
      </Page>
    </>
  );
};
