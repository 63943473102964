import React from "react";
import styles from "./style.module.scss";

interface Props {
  artwork: {
    image: string;
    title: string;
    dimensions: string;
    medium: string;
    date: string;
  };
}

export const WorkCard = (props: Props) => {
  const { artwork } = props;

  return (
    <div className={styles.box}>
      <div className={styles.boxContent}>
        <div>
          Titre : <b>{artwork.title}</b>
        </div>
        <div>
          Dimensions : <b>{artwork.dimensions}</b>
        </div>
        <div>
          Médium : <b>{artwork.medium}</b>
        </div>
        <div>
          Date : <b>{artwork.date}</b>
        </div>
      </div>
    </div>
  );
};
