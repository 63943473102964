import React, { useState } from "react";

import { PersonalityTestContent } from "../PersonalityTestContent/PersonalityTestContent";
import { PersonalityTestIntro } from "../PersonalityTestIntro/PersonalitytTestIntro";
import styles from "./style.module.scss";

export const PersonalityTest = () => {
  const [zoomed, setZoomed] = useState(false);

  return (
    <>
      <div
        className={zoomed ? styles.backgroundZoomed : styles.background}
      ></div>
      <div className={styles.container}>
        {!zoomed ? (
          <PersonalityTestIntro setZoomed={setZoomed} />
        ) : (
          <PersonalityTestContent />
        )}
      </div>
    </>
  );
};
