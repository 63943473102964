import React from "react";
import styles from "./style.module.scss";

interface Props {
  src: string;
  children: React.ReactNode;
}

export const ImageOverlay = (props: Props) => {
  const { src, children } = props;

  return (
    <div className={styles.imageContainer}>
      <img className={styles.image} alt="exploInvisible" src={src} />
      <div className={styles.overlay}>{children}</div>
    </div>
  );
};
