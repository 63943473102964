import { Page } from "../Page/Page";
import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

export const About = () => {
  const navigate = useNavigate();

  return (
    <Page backgroundImage="/assets/propos2.jpg">
      <div className={styles.container}>
        <div className={styles.highHalf}>
          <div className={styles.box}>
            <div className={styles.boxContent}>
              <div>
                À la recherche d'un cheminement artistique, je propose une
                plongée au cœur des énigmes de la communication humaine, de ces
                fils fragiles qui se nouent et se défont entre individus. Mon
                action se déploie en séries, chaque œuvre est une exploration
                dans cet univers chaotique où nos voix se perdent parfois dans
                le bruit de fond de l'incompréhension.
              </div>
              <div>
                Chaque trait, chaque forme que je façonne vise à donner voix à
                nos luttes intérieures, à nos aspirations collectives, et à nos
                cris muets. Mon approche embrasse les thèmes qui nous obsèdent,
                nos difficultés à échanger, à comprendre, à coexister. Dans un
                monde où l'avenir peut sembler sombre, je plonge dans les abîmes
                de notre nature, tout en invoquant une lueur d'espoir.
              </div>
              <div>
                Ma production est une invitation à explorer des territoires
                oniriques, des contrées dessinées où se côtoient ombres et
                rires, peuplées d'entité en quête de vérité, intimement liées
                par un fil secret au vivant. S'immerger dans mes créations,
                c'est ouvrir sa conscience à cet imaginaire primitif commun à
                tous.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.lowHalf}>
          <div
            className={styles.button}
            onClick={() => navigate("/about/personalitytest")}
            style={{ display: "none" }} // TODO remove this line when test will be ready
          >
            <img src="/assets/aboutButton.svg" alt="aboutButton" />
          </div>
        </div>
      </div>
    </Page>
  );
};
