import React, { useEffect, useState } from "react";

import { Page } from "../Page/Page";
import { Stack } from "@mui/material";
import { WorksMenuItem } from "../WorksMenuItem/WorksMenuItem";
import styles from "./style.module.scss";

export const WorksMenu = () => {
  return (
    <>
      <div className={styles.containerMobile}>
        <div className={styles.box}>
          <WorksMenuItem
            imageName="kaiju"
            year="2007"
            navigateTo="/works/kaiju"
          />
          <WorksMenuItem
            imageName="miroir"
            year="2010"
            navigateTo="/works/miroirdesens"
          />
          <WorksMenuItem
            imageName="paralogophobie"
            year="2012"
            navigateTo="/works/paralogophobie"
          />
          <WorksMenuItem
            imageName="paralogophobie2"
            year="2013"
            navigateTo="/works/paralogophobie2"
          />
          <Stack direction="row" gap={3} style={{ margin: "auto" }}>
            <WorksMenuItem
              imageName="vrmr"
              year="2017"
              navigateTo="/works/vrmr"
            />
            <div className={styles.notStartedWork}>
              <div className={styles.title}>CHEFFE</div>
              <div className={styles.description}>EN CRÉATION</div>
            </div>
          </Stack>
          <div className={styles.notStartedWork}>
            <div className={styles.title}>ACCABLANT</div>
            <div className={styles.description}>EN CRÉATION</div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.firstLine}>
            <WorksMenuItem
              imageName="kaiju"
              year="2007"
              navigateTo="/works/kaiju"
            />
            <WorksMenuItem
              imageName="miroir"
              year="2010"
              navigateTo="/works/miroirdesens"
            />
          </div>
          <div className={styles.secondLine}>
            <WorksMenuItem
              imageName="paralogophobie"
              year="2012"
              navigateTo="/works/paralogophobie"
            />
            <WorksMenuItem
              imageName="paralogophobie2"
              year="2013"
              navigateTo="/works/paralogophobie2"
            />
            <WorksMenuItem
              imageName="vrmr"
              year="2017"
              navigateTo="/works/vrmr"
            />
          </div>
          <div className={styles.thirdLine}>
            <div>
              <div className={styles.title}>CHEFFE</div>
              <div className={styles.description}>EN CRÉATION</div>
            </div>
            <div>
              <div className={styles.title}>ACCABLANT</div>
              <div className={styles.description}>EN CRÉATION</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
