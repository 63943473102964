import React, { useEffect, useState } from "react";

import { ChoiceType } from "domain/enum/choiceType";
import styles from "./style.module.scss";

interface Props {
  question: { question: string; agree: string; disagree: string };
  chunkIndex: number;
  questionIndex: number;
  responses: (string | null)[];
  setResponses: React.Dispatch<React.SetStateAction<(string | null)[]>>;
  setChunkIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const QuestionIcons = (props: Props) => {
  const {
    question,
    chunkIndex,
    setChunkIndex,
    questionIndex,
    responses,
    setResponses,
  } = props;

  const [selected, setSelected] = useState<number | null>(null);

  useEffect(() => {
    if (
      responses
        .slice(0, (chunkIndex + 1) * 12)
        .every((element) => element !== null)
    ) {
      setChunkIndex(chunkIndex + 1);
      setSelected(null);
    }
  }, [chunkIndex, responses, setChunkIndex]);

  const getChoiceType = (index: number) => {
    if (index === 3) {
      return ChoiceType.Neutral;
    }
    if (index > 3) {
      return question.agree;
    }

    return question.disagree;
  };

  const handleSelect = (index: number) => {
    setSelected(index);
    const newResponses = [...responses];
    newResponses[questionIndex + chunkIndex * 12] = getChoiceType(index);
    setResponses(newResponses);
  };

  return (
    <div className={styles.icons}>
      <img
        onClick={() => handleSelect(0)}
        className={styles.image}
        alt="bigred"
        src={
          selected === 0
            ? "/assets/rectangles/red/filled/big.svg"
            : "/assets/rectangles/red/empty/big.svg"
        }
      />
      <img
        onClick={() => handleSelect(1)}
        className={styles.image}
        alt="mediumred"
        src={
          selected === 1
            ? "/assets/rectangles/red/filled/medium.svg"
            : "/assets/rectangles/red/empty/medium.svg"
        }
      />
      <img
        onClick={() => handleSelect(2)}
        className={styles.image}
        alt="smallred"
        src={
          selected === 2
            ? "/assets/rectangles/red/filled/small.svg"
            : "/assets/rectangles/red/empty/small.svg"
        }
      />
      <img
        onClick={() => handleSelect(3)}
        className={styles.image}
        alt="grey"
        src={
          selected === 3
            ? "/assets/rectangles/grey/filled.svg"
            : "/assets/rectangles/grey/empty.svg"
        }
      />
      <img
        onClick={() => handleSelect(4)}
        className={styles.image}
        alt="smallblue"
        src={
          selected === 4
            ? "/assets/rectangles/blue/filled/small.svg"
            : "/assets/rectangles/blue/empty/small.svg"
        }
      />
      <img
        onClick={() => handleSelect(5)}
        className={styles.image}
        alt="mediumblue"
        src={
          selected === 5
            ? "/assets/rectangles/blue/filled/medium.svg"
            : "/assets/rectangles/blue/empty/medium.svg"
        }
      />
      <img
        onClick={() => handleSelect(6)}
        className={styles.image}
        alt="bigblue"
        src={
          selected === 6
            ? "/assets/rectangles/blue/filled/big.svg"
            : "/assets/rectangles/blue/empty/big.svg"
        }
      />
    </div>
  );
};
